import type {
  ICommunityLocationPickerV2,
  ILocationPickerV2,
  ISpotLocationPickerV2
} from 'type/feed'

const getFirstBatchLocation = <
  T extends ISpotLocationPickerV2 | ICommunityLocationPickerV2
>(
  locations: T[]
) => {
  const firstBatchLocations: T[] = []

  let started = false
  for (const location of locations) {
    if (location.level_type !== 'spot' && location.level_type !== 'community') {
      if (started) {
        break
      }

      started = true
    }

    firstBatchLocations.push(location)
  }

  return firstBatchLocations
}

export default getFirstBatchLocation
