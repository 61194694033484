'use client'

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import * as React from 'react'

import cn, { type TwcComponentWithAsChild, twc } from 'util/cn'

interface IScrollArea
  extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  viewportRef?: React.RefObject<HTMLDivElement>
  viewportClassName?: string
  showScrollbar?: boolean
}
const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  IScrollArea
>(
  (
    {
      className,
      children,
      viewportRef,
      viewportClassName,
      showScrollbar = true,
      onScroll,
      ...props
    },
    ref
  ) => (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('relative overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        ref={viewportRef}
        className={cn(
          'ScrollAreaViewport h-full w-full rounded-[inherit]',
          viewportClassName
        )}
        onScroll={onScroll}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollAreaScrollBar
        orientation='vertical'
        className={showScrollbar ? '' : '!opacity-0'}
      >
        <ScrollAreaThumb />
      </ScrollAreaScrollBar>
      <ScrollAreaScrollBar
        orientation='horizontal'
        className={showScrollbar ? '' : '!opacity-0'}
      >
        <ScrollAreaThumb />
      </ScrollAreaScrollBar>
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
)
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

type ScrollAreaScrollBarProps = TwcComponentWithAsChild<
  typeof ScrollAreaPrimitive.ScrollAreaScrollbar
>
const ScrollAreaScrollBar = twc(
  ScrollAreaPrimitive.ScrollAreaScrollbar
).attrs<ScrollAreaScrollBarProps>(props => ({
  asChild: props.$asChild
}))`ScrollAreaScrollbar
relative flex touch-none select-none p-[2px]
data-[orientation=horizontal]:h-[10px] data-[orientation=vertical]:w-[10px] data-[orientation=horizontal]:flex-col`
ScrollAreaScrollBar.displayName = `twc(${ScrollAreaPrimitive.ScrollAreaScrollbar.displayName}))`

type ScrollAreaThumbProps = TwcComponentWithAsChild<
  typeof ScrollAreaPrimitive.ScrollAreaThumb
>
const ScrollAreaThumb = twc(
  ScrollAreaPrimitive.ScrollAreaThumb
).attrs<ScrollAreaThumbProps>(props => ({
  asChild: props.$asChild
}))`ScrollAreaThumb
flex-1 rounded-full bg-playseeGray-gray3
before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[20px] before:w-full before:min-w-[20px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']`
ScrollAreaThumb.displayName = `twc(${ScrollAreaPrimitive.ScrollAreaThumb.displayName}))`

const ScrollSnapItem = twc.div`h-full w-full snap-start snap-always`
ScrollSnapItem.displayName = 'ScrollSnapItem'

export { ScrollArea, ScrollAreaScrollBar, ScrollAreaThumb, ScrollSnapItem }
