export interface IThumbnailObjectV2 {
  url: string
  blurred_url: string
  base64: string | null
}
export interface IThumbnailV2 {
  pixel_1080: IThumbnailObjectV2
  pixel_720: IThumbnailObjectV2
  pixel_200: IThumbnailObjectV2
  pixel_196: IThumbnailObjectV2
  pixel_64: IThumbnailObjectV2
}
export interface IVideoHlsObjectV2 {
  url: string
  is_mute: boolean
}
export interface IVideoHlsV2 {
  pixel_1080: IVideoHlsObjectV2
  pixel_720: IVideoHlsObjectV2
  pixel_360: IVideoHlsObjectV2
  pixel_160: IVideoHlsObjectV2
}

export interface IImageAssetV2 {
  // id: number
  id: string
  type: 'image' | 'random_animal'
  image_metadata: {
    display_url: string
    height: number
    width: number
    rotation: number
    thumbnail: IThumbnailV2
  }[]
}
export function isImageAsset(asset: IAssetV2): asset is IImageAssetV2 {
  return asset.type === 'image' || asset.type === 'random_animal'
}

export interface IWeblinkAssetV2 {
  // id: number
  id: string
  type: 'weblink'
  weblink_metadata: {
    display_url: string
    title: string
    thumbnail: IThumbnailV2
  }
}
export function isWeblinkAsset(asset: IAssetV2): asset is IWeblinkAssetV2 {
  return asset.type === 'weblink'
}

export interface IVideoAssetV2 {
  id: string // data source is number, but we convert it to string (cause number is too big to represent in JS)
  type: 'video'
  video_metadata: {
    display_url: string
    height: number
    width: number
    duration: number
    rotation: number
    hls: {
      version: string
      hls_264_url: IVideoHlsV2
      hls_265_url: IVideoHlsV2
    }
    thumbnail: IThumbnailV2
  }
}
export function isVideoAsset(asset: IAssetV2): asset is IVideoAssetV2 {
  return asset.type === 'video'
}

export type IAssetV2 = IImageAssetV2 | IWeblinkAssetV2 | IVideoAssetV2
