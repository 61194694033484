import { useQueries } from '@tanstack/react-query'
import { getHomeLocationsV2 } from 'api/feed'
import { useLocale } from 'next-intl'

import { TEMP_USER_ID } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { useIpLocationStore } from 'store/server-context/ip-location'
import { useSessionStore } from 'store/server-context/session'
import type {
  ICommunityLocationPickerV2,
  ISpotLocationPickerV2
} from 'type/feed'

export const useSearchSuggestion = () => {
  const locale = useLocale()
  const ipLocation = useIpLocationStore(state => state.ipLocation)
  const session = useSessionStore(state => state.session)

  const { x, y } = (() => {
    if (ipLocation) {
      const { lat, lng } = ipLocation.ip_geo_point
      return {
        x: `${lng}`,
        y: `${lat}`
      }
    } else {
      return {
        x: '0',
        y: '0'
      }
    }
  })()

  const result = useQueries({
    queries: [
      {
        queryKey: ['spot', 'suggestion', x, y],
        queryFn: async () => {
          const { data } = await getHomeLocationsV2(clientFetch, {
            info: {
              country: ipLocation?.country_code || '',
              language: locale,
              user_id: session.guest ? TEMP_USER_ID : session.user_id,
              user_coordinate: {
                lat: y,
                lng: x
              }
            },
            placement: 'feed-recommend',
            content_type: 'spot',
            content_size: 5,
            content_coordinate: {
              lat: y,
              lng: x
            }
          })

          return data as ISpotLocationPickerV2[]
        }
      },
      {
        queryKey: ['community', 'suggestion', x, y],
        queryFn: async () => {
          const { data } = await getHomeLocationsV2(clientFetch, {
            info: {
              country: ipLocation?.country_code || '',
              language: locale,
              user_id: session.guest ? TEMP_USER_ID : session.user_id,
              user_coordinate: {
                lat: y,
                lng: x
              }
            },
            placement: 'community-recommend',
            content_type: 'community',
            content_size: 5,
            content_coordinate: {
              lat: y,
              lng: x
            }
          })

          return data as ICommunityLocationPickerV2[]
        }
      }
    ]
  })

  return result
}
