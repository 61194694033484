'use client'

import Link from 'component/block/link'
import IconPin from 'asset/icon/geo/pin_s.svg'
import LoadingItems from 'component/block/loading/items'
import SuggestedListItem from 'component/block/suggestedListItem'
import { useTranslations } from 'next-intl'
import type { ISpotLocationPickerV2 } from 'type/feed'
import { Image } from 'component/ui/image'
import { normalizeAsset } from 'util/normalizeAsset'
import { scrollIndexStore } from 'store/global/feed-scroll-index'
import { useLocationPickerSuggestion } from 'hook/useLocationPickerSuggestion'
import { useRouter } from 'lib/next-intl'
import { useSpotLocationPickerStore } from 'store/global/location-picker'
import {
  ListContainer,
  LoadingWrapper,
  SuggestedForYou,
  Title
} from 'component/block/layoutSuggested'

const PlaceSuggestion = () => {
  const t = useTranslations()

  const pickedLocation = useSpotLocationPickerStore(
    state => state.pickedLocation
  )

  const { showLoading, showLocations } =
    useLocationPickerSuggestion<ISpotLocationPickerV2>('spot', pickedLocation)

  return (
    <div>
      <SuggestedForYou>{t('common.suggested_for_you')}</SuggestedForYou>
      <ListContainer>
        {showLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          showLocations.map((location, index) =>
            location.level_type !== 'spot' ? (
              <Title key={'place' + index} title={location.display_name}>
                {location.display_name}
              </Title>
            ) : (
              <Link
                key={'place' + location.place?.id}
                href={`/place/${location.place?.id}`}
                title={location.display_name}
              >
                <SuggestedListItem
                  key={'place' + index}
                  icon={
                    <Image
                      src={normalizeAsset(location.asset).src}
                      alt={location.display_name}
                      fill={true}
                      fallback={<IconPin className='fill-current sq-[24px]' />}
                    />
                  }
                  title={location.display_name}
                />
              </Link>
            )
          )
        )}
      </ListContainer>
    </div>
  )
}

export default PlaceSuggestion
