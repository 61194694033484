import { useSyncExternalStore } from 'react'

let scrollIndex = 0
type Listener = (index: number) => void
const listeners = new Set<Listener>()

export const scrollIndexStore = {
  setIndex(index: number) {
    scrollIndex = index
    emitScrollIndexChange(scrollIndex)
  },
  subscribe(listener: Listener) {
    listeners.add(listener)
    return () => {
      listeners.delete(listener)
    }
  },
  getSnapshot() {
    return scrollIndex
  },
  getServerSnapshot() {
    return 0
  }
}

function emitScrollIndexChange(index: number) {
  for (let listener of listeners) {
    listener(index)
  }
}

export const useScrollIndex = () =>
  useSyncExternalStore(
    scrollIndexStore.subscribe,
    scrollIndexStore.getSnapshot,
    scrollIndexStore.getServerSnapshot
  )
