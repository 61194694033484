const Debounce = (ms: number, func: Function): ((...args: any[]) => void) => {
  let timer: number | undefined

  return (...args: any[]) => {
    clearTimeout(timer)

    timer = window.setTimeout(() => func(...args), ms)
  }
}

export default Debounce
