import { create } from 'zustand'
interface IPopupStoreState {
  mask: {
    auth: boolean
    download: boolean
    share: boolean
    account: boolean
  }
}

interface IPopupStoreAction {
  showAuthPopup: () => void
  showDownloadPopup: () => void
  showSharePopup: () => void
  showAccountPopup: () => void
  hideAuthPopup: () => void
  hideDownloadPopup: () => void
  hideSharePopup: () => void
  hideAccountPopup: () => void
}

export const usePopupStore = create<IPopupStoreState & IPopupStoreAction>(
  (set, get) => ({
    mask: {
      auth: false,
      download: false,
      share: false,
      account: false
    },
    showAuthPopup: () => {
      set({ mask: { ...get().mask, auth: true } })
    },
    showDownloadPopup: () => {
      set({ mask: { ...get().mask, download: true } })
    },
    hideAuthPopup: () => {
      set({ mask: { ...get().mask, auth: false } })
    },
    hideDownloadPopup: () => {
      set({ mask: { ...get().mask, download: false } })
    },
    showSharePopup: () => {
      set({ mask: { ...get().mask, share: true } })
    },
    hideSharePopup: () => {
      set({ mask: { ...get().mask, share: false } })
    },
    showAccountPopup: () => {
      set({ mask: { ...get().mask, account: true } })
    },
    hideAccountPopup: () => {
      set({ mask: { ...get().mask, account: false } })
    }
  })
)
