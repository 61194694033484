import { create } from 'zustand'

export interface IRefreshPageState {
  refreshingId: string
}

export interface IRefreshPageAction {
  refreshPage: () => void
}

const createRefreshPageStore = () => {
  return create<IRefreshPageState & IRefreshPageAction>()(set => ({
    refreshingId: '',
    refreshPage: () => set({ refreshingId: Date.now().toString() })
  }))
}

export const useRefreshProfilePageStore = createRefreshPageStore()
export const useRefreshSearchPageStore = createRefreshPageStore()
