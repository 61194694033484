import { useQuery } from '@tanstack/react-query'
import { getHomeLocationsV2 } from 'api/feed'
import { useLocale } from 'next-intl'

import { TEMP_USER_ID } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { useIpLocationStore } from 'store/server-context/ip-location'
import { useSessionStore } from 'store/server-context/session'
import type {
  ICommunityLocationPickerV2,
  ISpotLocationPickerV2
} from 'type/feed'

export const useLocationPickerSuggestion = <
  T extends ISpotLocationPickerV2 | ICommunityLocationPickerV2
>(
  contentType: 'spot' | 'community',
  pickedLocation: T | null
) => {
  const locale = useLocale()
  const ipLocation = useIpLocationStore(state => state.ipLocation)
  const session = useSessionStore(state => state.session)

  const yourXy = ipLocation?.ip_geo_point || { lat: 0, lng: 0 }

  const { data, isLoading } = useQuery({
    queryKey: [contentType, 'suggestion', pickedLocation],
    queryFn: async () => {
      // only return when contentType === 'spot' and pickedLocation is not null
      const contentCoordinate = (() => {
        if (contentType === 'spot') {
          if (pickedLocation) {
            const location = pickedLocation as ISpotLocationPickerV2
            if (location.place) {
              return location.place.geo_point
            }
            if (location.location) {
              return {
                lat: `${location.location.centroid_geo_point.lat}`,
                lng: `${location.location.centroid_geo_point.lng}`
              }
            }
          }
        }
        // fix server error
        return {
          lat: `${yourXy.lat}`,
          lng: `${yourXy.lng}`
        }
      })()

      // only return when contentType === 'community' and pickedLocation is not null
      const locationId = (() => {
        if (contentType === 'community') {
          if (pickedLocation) {
            const location = pickedLocation as ICommunityLocationPickerV2
            return location.location?.id
          }
        }

        return undefined
      })()

      const { data } = await getHomeLocationsV2(clientFetch, {
        info: {
          country: ipLocation?.country_code || '',
          language: locale,
          user_id: session.guest ? TEMP_USER_ID : session.user_id,
          user_coordinate: {
            lat: `${yourXy.lat}`,
            lng: `${yourXy.lng}`
          }
        },
        placement:
          contentType === 'spot' ? 'feed-recommend' : 'community-recommend',
        content_type: contentType,
        content_size: 5,
        location_id: locationId,
        content_coordinate: contentCoordinate
      })

      return data as T[]
    }
  })

  return {
    showLocations: data || [],
    showLoading: isLoading
  }
}
