'use client'

import { type ReactNode } from 'react'

import { usePathname } from 'lib/next-intl'
import { useIsMobile } from 'store/server-context/device'
import cn from 'util/cn'
import currentPage from 'util/currentPage'
import AppMobileLayout from './app-mobile'
import SideLeft from './side-left'
import SideRight from './side-right'

function AppLayoutWithSidebar({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  const isMobile = useIsMobile()

  if (isMobile) {
    return <AppMobileLayout>{children}</AppMobileLayout>
  }

  const { page } = currentPage(pathname)

  const isSpotFeed = page === 'spot-feed'
  const isSpot = page === 'spot'
  const isPixie = page === 'pixie'

  return (
    <div
      className={cn(
        'relative mx-auto flex min-h-full w-[100vw] max-w-[--max-app-size] justify-center min768:px-[28px] min1080:px-[24px]',
        isSpotFeed || isSpot || isPixie
          ? 'fixed bottom-0 left-1/2 top-[--fixed-top] min-h-[unset] -translate-x-1/2 transform'
          : 'pt-[--fixed-top]'
      )}
    >
      <SideLeft />
      <main
        className={cn(
          'playsee-main',
          'min-h-[inherit] flex-grow basis-0',
          (isSpotFeed || isSpot || isPixie) && 'h-full min-h-[unset]'
        )}
      >
        {children}
      </main>

      <SideRight />
    </div>
  )
}

export default AppLayoutWithSidebar
