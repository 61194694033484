import { twc } from 'util/cn'

export const Container = twc.footer`relative p-[16px] text-sm font-semibold text-label-l3`
Container.displayName = 'Container'

export const Item = twc.div`flex flex-shrink-0 flex-grow-0 basis-auto text-center text-label-l3`
Item.displayName = 'Item'

export const List = twc.ul`w-full flex flex-wrap items-center gap-x-[12px] gap-y-[4px] [&>a]:text-label-l3`
List.displayName = 'List'
