import { HeadshotType } from 'constant'
import { isImageAsset, isVideoAsset, type IAssetV2 } from 'type/asset'

export const normalizeAsset = (
  headshotAsset: IAssetV2 | null | undefined,
  showVideo: boolean = false
): {
  type: HeadshotType
  src: string
  width: number
  height: number
} => {
  if (!headshotAsset) {
    return {
      src: '',
      type: HeadshotType.None,
      width: 0,
      height: 0
    }
  }

  if (isImageAsset(headshotAsset)) {
    return {
      src: headshotAsset.image_metadata[0].display_url,
      type: HeadshotType.Image,
      width: headshotAsset.image_metadata[0].width,
      height: headshotAsset.image_metadata[0].height
    }
  }

  if (isVideoAsset(headshotAsset)) {
    if (showVideo) {
      return {
        src: headshotAsset.video_metadata.display_url,
        type: HeadshotType.Video,
        width: headshotAsset.video_metadata.width,
        height: headshotAsset.video_metadata.height
      }
    }

    let src = '',
      thumbnail = headshotAsset.video_metadata.thumbnail
    if (thumbnail.pixel_720?.url) {
      src = thumbnail.pixel_720.url
    } else if (thumbnail.pixel_200?.url) {
      src = thumbnail.pixel_200.url
    } else if (thumbnail.pixel_196?.url) {
      src = thumbnail.pixel_196.url
    } else {
      src = thumbnail.pixel_64?.url || ''
    }

    return {
      src,
      type: HeadshotType.Image,
      width: headshotAsset.video_metadata.width,
      height: headshotAsset.video_metadata.height
    }
  }

  return {
    src: '',
    type: HeadshotType.None,
    width: 0,
    height: 0
  }
}
