'use client'

import { useTranslations } from 'next-intl'
import toast from 'react-hot-toast'
import IconCommunity from 'asset/icon/home/us_s.svg'
import {
  ListContainer,
  LoadingWrapper,
  SuggestedForYou,
  Title
} from 'component/block/layoutSuggested'
import Link from 'component/block/link'
import LoadingItems from 'component/block/loading/items'
import SuggestedListItem from 'component/block/suggestedListItem'
import { Image } from 'component/ui/image'
import { useLocationPickerSuggestion } from 'hook/useLocationPickerSuggestion'
import { usePathname, useRouter } from 'lib/next-intl'
import { useCommunityLocationPickerStore } from 'store/global/location-picker'
import type { ICommunityLocationPickerV2 } from 'type/feed'
import { normalizeAsset } from 'util/normalizeAsset'

const CommunitySuggestion = () => {
  const t = useTranslations()

  const pickedLocationForSuggested = useCommunityLocationPickerStore(
    state => state.pickedLocationForSuggested
  )

  const setPickedLocation = useCommunityLocationPickerStore(
    state => state.setPickedLocation
  )
  const router = useRouter()
  const pathname = usePathname()

  const { showLoading, showLocations } =
    useLocationPickerSuggestion<ICommunityLocationPickerV2>(
      'community',
      pickedLocationForSuggested
    )

  const handleClickItem = (location: ICommunityLocationPickerV2) => {
    toast.remove()
    toast(
      t('feed.change_place_level_success', { location: location.display_name }),
      {
        duration: 2000
      }
    )
    setPickedLocation(location)
    if (pathname !== '/community') {
      router.push('/community')
    }
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <SuggestedForYou>{t('common.suggested_for_you')}</SuggestedForYou>
      <ListContainer>
        {showLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          showLocations.map((location, index) =>
            location.level_type !== 'community' ? (
              <Title key={'search' + index} title={location.display_name}>
                {location.display_name}
              </Title>
            ) : (
              <Link
                key={'search' + index}
                href={`/community/${location.content_ids?.[0]}`}
                title={location.display_name}
              >
                <SuggestedListItem
                  icon={
                    <Image
                      src={normalizeAsset(location.asset).src}
                      alt={location.display_name}
                      fill={true}
                      fallback={
                        <IconCommunity className='fill-current sq-[24px]' />
                      }
                    />
                  }
                  squareIcon={true}
                  title={location.display_name}
                  subTitle={location.description}
                />
              </Link>
            )
          )
        )}
      </ListContainer>
    </div>
  )
}

export default CommunitySuggestion
