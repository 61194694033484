import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { IRespV2 } from 'type/common'
import type { IHashtagCommentV2, IHashtagV2 } from 'type/hashtag'

// V2
export const getHashtagV2 = async (
  fetcher: Fetch,
  hashtag: string
): Promise<IRespV2<IHashtagV2>> => {
  const res = await fetcher(API_V2.CONTENT + `/hashtag/${hashtag}`)

  return res
}

/**
 *
 * @param query ex: size=12&page_token=xxx
 */
export const getHashtagCommentsV2 = async (
  fetcher: Fetch,
  hashtag: string,
  query: string
): Promise<
  IRespV2<{
    comments: IHashtagCommentV2[]
    next_page_token: string
  }>
> => {
  const url = new URL(API_V2.CONTENT + `/hashtag/${hashtag}/comments`)
  url.search = `?${query}`

  const res = await fetcher(url.toString())

  return res
}

/**
 *
 * @param query ex: place_id=xxx&keyword=xxx
 */
export const getSuggestedHashtagsV2 = async (
  fetcher: Fetch,
  query: string
): Promise<
  IRespV2<{
    hashtags: Omit<IHashtagV2, 'personal' | 'thumbnail_asset_id'>[]
  }>
> => {
  const url = new URL(API_V2.CONTENT + `/hashtag/suggestion`)
  url.search = `?${query}`
  const res = await fetcher(url.toString())

  return res
}

export const followHashtagV2 = async (
  fetcher: Fetch,
  hashtag: string
): Promise<IRespV2<null>> => {
  const url = new URL(API_V2.CONTENT + `/hashtag/${hashtag}/follow`)
  const res = await fetcher(url.toString(), {
    method: 'PUT'
  })

  return res
}

export const unfollowHashtagV2 = async (
  fetcher: Fetch,
  hashtag: string
): Promise<IRespV2<null>> => {
  const url = new URL(API_V2.CONTENT + `/hashtag/${hashtag}/follow`)
  const res = await fetcher(url.toString(), {
    method: 'DELETE'
  })

  return res
}
