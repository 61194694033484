const uniqueArray = <T>(arr: T[], key?: keyof T) => {
  if (!key) return Array.from(new Set(arr))

  const list: T[] = []
  const map = new Map<T[keyof T], boolean>()

  for (const item of arr) {
    if (!map.has(item[key])) {
      map.set(item[key], true)
      list.push(item)
    }
  }

  return list
}

export default uniqueArray
