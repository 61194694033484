import { useQuery } from '@tanstack/react-query'

import { getSuggestedHashtagsV2 } from 'api/hashtag'
import { clientFetch } from 'lib/fetch/client'
import uniqueArray from 'util/uniqueArray'

export const useHashtagSuggestion = (hashtag: string, take?: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['hashtag/suggestion', hashtag, take],
    queryFn: async () => {
      const {
        data: { hashtags }
      } = await getSuggestedHashtagsV2(clientFetch, `keyword=${hashtag}`)

      const uniqueHashtags = uniqueArray(hashtags, 'name').slice(
        0,
        take ? take : hashtags.length
      )

      return uniqueHashtags
    }
  })

  return {
    hashtagSuggestion: data || [],
    isLoading
  }
}
