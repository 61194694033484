'use client'

import DownloadBoard from 'component/block/downloadBoard'
import MobileNav from 'component/block/nav/mobileNav'

const Overlays = () => {
  return (
    <div>
      <MobileNav />
      <DownloadBoard />
    </div>
  )
}

export default Overlays
