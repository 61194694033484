'use client'

import { ScrollArea } from 'component/ui/scroll-area'
import { usePathname } from 'lib/next-intl'
import cn from 'util/cn'
import currentPage from 'util/currentPage'
import Nav from '../nav'

const SideLeft = () => {
  const pathname = usePathname()

  const { page } = currentPage(pathname)

  const isSpotFeed = page === 'spot-feed'
  const isSpot = page === 'spot'
  const isPixie = page === 'pixie'

  return (
    <div className='w-[--side-left-width] shrink-0 grow-0 shadow-[inset_-1px_0_0_0_var(--glass-special-separators)]'>
      <div
        className={cn(
          'fixed bottom-0 w-[--side-left-width]',
          isSpotFeed || isSpot || isPixie ? 'top-0' : 'top-[--fixed-top]'
        )}
      >
        <ScrollArea className='h-full'>
          <div className='min768:py-[8px]'>
            <Nav />
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default SideLeft
