import { useTranslations } from 'next-intl'
import { useState } from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle
} from 'component/ui/alert-dialog'
import { Button } from 'component/ui/button'
import { usePopupStore } from 'store/global/popup'
import { useIsMobile } from 'store/server-context/device'
import { useSessionStore } from 'store/server-context/session'
import { ButtonGroup } from './authButton.style'

interface IAuthButton {
  variant?: 'outline' | 'outline-white'
  prefix?: React.ReactNode
}
const AuthButton = (props: IAuthButton) => {
  const { variant, prefix } = props
  const t = useTranslations()
  const session = useSessionStore(state => state.session)
  const showPopup = usePopupStore(state => state.showAuthPopup)
  const [isLogout, setIsLogout] = useState(false)
  const isMobile = useIsMobile()

  const logout = () => {
    setIsLogout(true)
  }

  const handleButtonClick = () => {
    if (session.guest) {
      showPopup()
    } else {
      logout()
    }
  }

  const confirmSignOut = () => {
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = '/api/auth/logout'
    document.body.appendChild(form)
    form.submit()
  }

  return (
    <>
      <Button
        $variant={variant ?? 'outline'}
        $size={isMobile ? 'sm' : 'md'}
        $radius={isMobile ? 'md' : 'lg'}
        onClick={handleButtonClick}
        className='whitespace-nowrap'
      >
        {prefix}
        {!session.guest ? t('common.logout') : t('common.login')}
      </Button>
      <AlertDialog open={isLogout} onOpenChange={setIsLogout}>
        <AlertDialogPortal>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogTitle className='mb-[16px]'>
              {t('auth.logout.title', { username: 'Playsee' })}
            </AlertDialogTitle>
            <ButtonGroup>
              <AlertDialogAction asChild={true}>
                <Button
                  $variant='primary'
                  $size='lg'
                  $radius='xl'
                  onClick={confirmSignOut}
                >
                  {t('auth.logout.confirm')}
                </Button>
              </AlertDialogAction>
              <AlertDialogCancel asChild={true}>
                <Button
                  $variant='secondary'
                  $size='lg'
                  $radius='xl'
                  onClick={() => setIsLogout(false)}
                >
                  {t('auth.logout.decline')}
                </Button>
              </AlertDialogCancel>
            </ButtonGroup>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </>
  )
}

export default AuthButton
