import { type MouseEvent, type ReactNode } from 'react'

import cn from 'util/cn'

interface ISuggestedListItem {
  icon: ReactNode
  theme?: 'light' | 'still'
  squareIcon?: boolean
  suffix?: ReactNode
  title: string
  subTitle?: string
  className?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}
const SuggestedListItem = (props: ISuggestedListItem) => {
  const {
    icon,
    suffix,
    title,
    subTitle,
    onClick,
    className,
    theme = 'light',
    squareIcon = false
  } = props
  return (
    <div
      onClick={onClick}
      title={title}
      className={cn(
        'flex max-w-full items-center px-[16px] py-[12px]',
        onClick && 'cursor-pointer',
        className
      )}
    >
      <div
        className={cn(
          'relative isolate mr-[8px] flex h-[48px] w-[48px] flex-shrink-0 items-center justify-center overflow-hidden',
          squareIcon ? 'rounded-[6px]' : 'rounded-full',
          theme === 'light'
            ? 'bg-background-2nd text-playseeGray-gray2'
            : 'bg-background_still-2nd text-playseeGray_still-gray2'
        )}
      >
        {icon}
      </div>
      <div className='flex-1 text-md font-semibold'>
        <div
          className={cn(
            'suggestedTitle line-clamp-2 min-w-full max-w-0',
            theme === 'light' ? 'text-label-l1' : 'text-label_still-l1'
          )}
        >
          {title}
        </div>
        {subTitle && (
          <div
            className={cn(
              'min-w-full max-w-0 text-ellipsis text-body',
              theme === 'light' ? 'text-label-l2' : 'text-label_still-l2'
            )}
          >
            {subTitle}
          </div>
        )}
      </div>
      {suffix && (
        <div className='ml-[16px] flex-shrink-0 flex-grow-0 basis-auto'>
          {suffix}
        </div>
      )}
    </div>
  )
}

export default SuggestedListItem
