import Nav from 'component/block/nav'
import { usePathname } from 'lib/next-intl'
import { useIsMobile } from 'store/server-context/device'
import cn from 'util/cn'
import currentPage from 'util/currentPage'

const MOBILE_NAV_ACTION = 'mobile-nav-action'

export const getMobileNavActionNode = () =>
  document.getElementById(MOBILE_NAV_ACTION)

const MobileNav = () => {
  const pathname = usePathname()
  const isMobile = useIsMobile()

  const { page } = currentPage(pathname)

  if (!isMobile) {
    return null
  }

  const isSpotFeed = page === 'spot-feed'

  return (
    <div className='fixed bottom-[--cookie-hint-height] left-0 z-mobile-nav flex w-full flex-col'>
      <div id={MOBILE_NAV_ACTION}></div>
      <div
        className={cn(
          'flex h-[48px] w-full backdrop-blur-[10px]',
          isSpotFeed
            ? 'bg-background_elevated_still-1st [&_a]:text-label_still-l1'
            : 'bg-glass_ios-20blur_light'
        )}
      >
        <div className='mx-auto flex h-full w-[330px] items-center justify-between '>
          <Nav showProfile={true} />
        </div>
      </div>
    </div>
  )
}

export default MobileNav
