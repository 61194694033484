'use client'

import React from 'react'

import { usePathname } from 'lib/next-intl'
import cn from 'util/cn'
import currentPage from 'util/currentPage'

const AppMobileLayout = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname()
  const { page } = currentPage(pathname)

  const isSpotFeed = page === 'spot-feed'
  const isSpot = page === 'spot'
  const isPixie = page === 'pixie'

  return (
    <div
      className={cn(
        'relative mx-auto flex min-h-full w-[100vw] max-w-[--max-app-size] justify-center',
        isSpotFeed || isSpot || isPixie
          ? [
              'fixed bottom-0 left-1/2 top-[--fixed-top] min-h-[unset] -translate-x-1/2 transform',
              isPixie
                ? 'bottom-[calc(var(--cookie-hint-height)_+_48px)]'
                : 'bottom-[calc(var(--cookie-hint-height)_+_96px)]'
            ]
          : 'pt-[--fixed-top]',
        isSpotFeed && 'top-0'
      )}
    >
      <main
        className={cn(
          'playsee-main',
          'min-h-full w-full flex-grow basis-0',
          isSpotFeed || isSpot || isPixie
            ? 'h-full min-h-[unset]'
            : 'pb-[calc(var(--cookie-hint-height)_+_96px)]'
        )}
      >
        {children}
      </main>
    </div>
  )
}

export default AppMobileLayout
