'use client'

import { useEffect } from 'react'

import { usePathname } from 'lib/next-intl'
import { userPausedStore } from 'store/global/video'
import currentPage from 'util/currentPage'

const ResetUserPaused = () => {
  const pathname = usePathname()
  const { page } = currentPage(pathname)

  useEffect(() => {
    userPausedStore.reset()
  }, [page])

  return null
}

export default ResetUserPaused
