'use client'

import { useTranslations } from 'next-intl'
import toast from 'react-hot-toast'

import IconPin from 'asset/icon/geo/pin_s.svg'
import {
  ListContainer,
  LoadingWrapper,
  SuggestedForYou,
  Title
} from 'component/block/layoutSuggested'
import LoadingItems from 'component/block/loading/items'
import SuggestedListItem from 'component/block/suggestedListItem'
import { Image } from 'component/ui/image'
import { useLocationPickerSuggestion } from 'hook/useLocationPickerSuggestion'
import { scrollIndexStore } from 'store/global/feed-scroll-index'
import { useSpotLocationPickerStore } from 'store/global/location-picker'
import type { ISpotLocationPickerV2 } from 'type/feed'
import { normalizeAsset } from 'util/normalizeAsset'

const SpotSuggestion = () => {
  const t = useTranslations()

  const pickedLocation = useSpotLocationPickerStore(
    state => state.pickedLocation
  )
  const setPickedLocation = useSpotLocationPickerStore(
    state => state.setPickedLocation
  )

  const { showLoading, showLocations } =
    useLocationPickerSuggestion<ISpotLocationPickerV2>('spot', pickedLocation)

  const handleClickItem = (location: ISpotLocationPickerV2) => {
    toast.remove()
    toast(
      t('feed.change_place_level_success', { location: location.display_name }),
      {
        duration: 2000
      }
    )
    scrollIndexStore.setIndex(0)
    setPickedLocation(location)
  }

  return (
    <div>
      <SuggestedForYou>{t('common.suggested_for_you')}</SuggestedForYou>
      <ListContainer>
        {showLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          showLocations.map((location, index) =>
            location.level_type !== 'spot' ? (
              <Title key={'search' + index} title={location.display_name}>
                {location.display_name}
              </Title>
            ) : (
              <SuggestedListItem
                key={'search' + index}
                icon={
                  <Image
                    src={normalizeAsset(location.asset).src}
                    alt={location.display_name}
                    fill={true}
                    fallback={<IconPin className='fill-current sq-[24px]' />}
                  />
                }
                title={location.display_name}
                onClick={() => handleClickItem(location)}
              />
            )
          )
        )}
      </ListContainer>
    </div>
  )
}

export default SpotSuggestion
