import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { ICommentV2 } from 'type/comment'
import type { IRespV2 } from 'type/common'
import type {
  IInStreamCommunityReqV2,
  IInStreamCommunityV2,
  IInStreamSpotReqV2,
  IInStreamSpotV2,
  ILocationPickerV2,
  ILocationsReqV2
} from 'type/feed'

// V2
export interface ISpotsInStreamRespV2 {
  session: {
    id: string
  }
  spots: IInStreamSpotV2[]
}
export const getSpotsInStreamV2 = async (
  fetcher: Fetch,
  req: IInStreamSpotReqV2
): Promise<IRespV2<ISpotsInStreamRespV2>> => {
  const res = await fetcher(API_V2.NEWS_FEED + '/home/instream', {
    method: 'POST',
    body: req
  })

  return res
}

export interface ICommunitiesInStreamRespV2 {
  session: {
    id: string
  }
  communities: IInStreamCommunityV2[]
}
export const getCommunitiesInStreamV2 = async (
  fetcher: Fetch,
  req: IInStreamCommunityReqV2
): Promise<IRespV2<ICommunitiesInStreamRespV2>> => {
  const res = await fetcher(
    API_V2.NEWS_FEED_V2 + '/community/get_communities',
    {
      method: 'POST',
      body: req
    }
  )

  return res
}

export const getHomeLocationsV2 = async (
  fetcher: Fetch,
  req: ILocationsReqV2
): Promise<IRespV2<ILocationPickerV2[]>> => {
  const res = await fetcher(API_V2.NEWS_FEED + '/geo/home_locations', {
    method: 'POST',
    body: req
  })

  return res
}

export const getCommunityPage = async (
  fetcher: Fetch,
  req?: {
    community_id: string
    user_id: string
    language: string
    size: number
    session_id: string
  }
): Promise<
  IRespV2<{
    comments: ICommentV2[]
    session: {
      id: string
      session_start_time: number
    }
  }> & { trace_id: string }
> => {
  const res = await fetcher(API_V2.NEWS_FEED + '/community/page', {
    method: 'POST',
    body: req
  })

  return res
}
