import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { IRespV1, IRespV2 } from 'type/common'
import type { IInStreamSpotV2 } from 'type/feed'
import type { IProfileCountV2, IProfileV2 } from 'type/profile'

// V2
export const getProfileV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2<IProfileV2>> => {
  const res = await fetcher(API_V2.USER + `/user/${id}/profile`)

  return res
}

export const getProfileByUidV2 = async (
  fetcher: Fetch,
  uid: string
): Promise<IRespV2<IProfileV2>> => {
  const res = await fetcher(API_V2.USER + `/user/profile?uid=${uid}`)

  return res
}

export const getProfileCountV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2<IProfileCountV2>> => {
  const res = await fetcher(API_V2.USER + `/user/${id}/profile/count`)

  return res
}

export interface IUpdateProfileV2 {
  about: string
  age_legal: boolean
  birthday: string
  comment: string
  country_code: string
  email: string
  headshot_id: string
  is_private: boolean
  name: string
  phone: string
  preview_on_wifi: boolean
  source: number
  uid: string
  web: string
}
export const updateProfileV2 = async (
  fetcher: Fetch,
  payload: Partial<IUpdateProfileV2>
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.USER + `/user/profile`, {
    method: 'PATCH',
    body: JSON.stringify(payload)
  })

  return res
}

/**
 * @param query ex: user_id=xxx&profile_user_id=xxx
 *
 * user_id: self user_id , profile_user_id: other user_id
 */
export const getProfileSuggestedSpot = async (
  fetcher: Fetch,
  query: string | URLSearchParams
): Promise<
  IRespV1<{
    spots: IInStreamSpotV2[]
  }> & { trace_id: string }
> => {
  const url = new URL(API_V2.NEWS_FEED + '/profile/suggested/spot')
  url.search = `?${query}`
  const res = await fetcher(url.toString())

  return res
}
