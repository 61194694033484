'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import { getProfileByUidV2 } from 'api/profile'
import { Button } from 'component/ui/button'
import {
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger
} from 'component/ui/dialog'
import { BASE_URL, FIREBASE_DYNAMIC_LINK } from 'constant'
import { clientFetch } from 'lib/fetch/client'
import { usePathname } from 'lib/next-intl'
import { usePopupStore } from 'store/global/popup'
import { cn, twc, type TwcComponentWithAsChild } from 'util/cn'
import currentPage from 'util/currentPage'
import GetAppModal from './getAppModal'

const iife = (fn: () => void) => {
  fn()
}

export const GetAppDesktop = ({
  className = '',
  variant,
  isMobile
}: {
  className?: string
  variant: 'outline-white' | 'outline' | undefined
  isMobile: boolean
}) => {
  const isOpen = usePopupStore(state => state.mask.download)
  const showPopup = usePopupStore(state => state.showDownloadPopup)
  const hidePopup = usePopupStore(state => state.hideDownloadPopup)

  const setIsOpen = (isOpen: boolean) => {
    if (isOpen) {
      showPopup()
    } else {
      hidePopup()
    }
  }
  const t = useTranslations()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild={true}>
        <Button
          $variant={variant ?? 'outline'}
          $size={isMobile ? 'sm' : 'md'}
          $radius={isMobile ? 'md' : 'lg'}
          className={cn('whitespace-nowrap', className)}
        >
          {t('overlays.button.get_app')}
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className='w-[424px]'>
          <DialogCloseIcon />
          <GetAppModal />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

const linkMap: Record<string, string> = {}

const useOpenAppLink = () => {
  const pathname = usePathname()
  const { page, id } = currentPage(pathname)

  const link = new URL(pathname, BASE_URL).toString()
  const longDynamicLink = FIREBASE_DYNAMIC_LINK.replace(
    '{link}',
    encodeURIComponent(link)
  )

  const [shortUrl, setShortUrl] = useState<string>('')

  const getShortUrl = async (link: string) => {
    const res = await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCUrkBoUP_yGgtvudhAWwnl2w5AwDeDQj4',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          longDynamicLink: link
        })
      }
    )
    const data = await res.json()
    return data.shortLink as string
  }

  useEffect(() => {
    if (linkMap[pathname]) {
      return
    }
    if (page === 'profile_new') {
      iife(async () => {
        const { data } = await getProfileByUidV2(clientFetch, id).catch(() => ({
          data: { user: { user_id: '' } }
        }))
        const longDynamicLink = FIREBASE_DYNAMIC_LINK.replace(
          '{link}',
          encodeURIComponent(`${BASE_URL}profile/${data.user.user_id}`)
        )
        const shortUrl = await getShortUrl(longDynamicLink)
        linkMap[pathname] = shortUrl
        setShortUrl(shortUrl)
      })
    } else {
      iife(async () => {
        const shortUrl = await getShortUrl(longDynamicLink)
        linkMap[pathname] = shortUrl
        setShortUrl(shortUrl)
      })
    }
  }, [page, id, link, pathname, longDynamicLink])

  return linkMap[pathname] || shortUrl || longDynamicLink
}

export const GetAppTablet = ({
  className = '',
  variant,
  isMobile
}: {
  className?: string
  variant: 'outline-white' | 'outline' | undefined
  isMobile: boolean
}) => {
  const t = useTranslations()
  const openAppLink = useOpenAppLink()

  return (
    <Button
      $variant={variant ?? 'outline'}
      $size={isMobile ? 'sm' : 'md'}
      $radius={isMobile ? 'md' : 'lg'}
      className={cn('whitespace-nowrap', className)}
    >
      <a href={openAppLink} target='_blank'>
        {t('common.open_app')}
      </a>
    </Button>
  )
}

export const GetAppMobile = () => {
  const t = useTranslations()
  const pathname = usePathname()
  const { page, id } = currentPage(pathname)

  const openAppLink = useOpenAppLink()
  const isCommunityComment = page === 'community' && !!pathname.match(/comment/)

  if (page === 'pixie') {
    return null
  }

  return (
    <div
      className={cn(
        'fixed bottom-[calc(var(--cookie-hint-height)_+_48px)] z-mobile-nav flex h-[48px] w-full items-center justify-between bg-playseeGray-gray1 px-[16px] text-md text-label_still-l1 backdrop-blur-[30px]',
        isCommunityComment && 'bottom-[calc(var(--cookie-hint-height)_+_112px)]'
      )}
    >
      <span>{t('overlays.download.get_app')}</span>
      <Button $variant='third' $size='sm' $radius='md' asChild={true}>
        <a href={openAppLink} target='_blank'>
          {t('overlays.download.open_app')}
        </a>
      </Button>
    </div>
  )
}
