import { create } from 'zustand'

interface PickerOpenState {
  pickerOpen: boolean
}

interface PickerOpenAction {
  setPickerOpen: (pickerOpen: PickerOpenState['pickerOpen']) => void
}

export const usePickerOpenStore = create<PickerOpenState & PickerOpenAction>()(
  set => ({
    pickerOpen: false,
    setPickerOpen: pickerOpen => set({ pickerOpen })
  })
)
