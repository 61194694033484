'use client'

// clone from react-content-loader
// added feature: afterMask
// to resolve the problem of the mask layer covered by the content
import {
  isValidElement,
  useId,
  type PropsWithChildren,
  type SVGAttributes
} from 'react'

export interface IContentLoaderProps extends SVGAttributes<SVGElement> {
  animate?: boolean
  animateBegin?: string
  backgroundColor?: string
  backgroundOpacity?: number
  baseUrl?: string
  foregroundColor?: string
  foregroundOpacity?: number
  gradientRatio?: number
  gradientDirection?: 'left-right' | 'top-bottom'
  interval?: number
  rtl?: boolean
  speed?: number
  title?: string
  uniqueKey?: string
  beforeMask?: JSX.Element | null
  afterMask?: JSX.Element | null
}

const ContentLoader = ({
  animate = true,
  animateBegin,
  backgroundColor = 'var(--background-2nd)',
  backgroundOpacity = 1,
  baseUrl = '',
  children,
  foregroundColor = 'var(--background-1st)',
  foregroundOpacity = 1,
  gradientRatio = 2,
  gradientDirection = 'left-right',
  uniqueKey,
  interval = 0.25,
  rtl = false,
  speed = 1.2,
  style = {},
  title = 'Loading...',
  beforeMask = null,
  afterMask = null,
  ...props
}: PropsWithChildren<IContentLoaderProps>) => {
  const uid = useId()
  const fixedId = uniqueKey || uid
  const idClip = `${fixedId}-diff`
  const idGradient = `${fixedId}-animated-diff`
  const idAria = `${fixedId}-aria`

  const rtlStyle = rtl ? { transform: 'scaleX(-1)' } : null
  const keyTimes = `0; ${interval}; 1`
  const dur = `${speed}s`
  const gradientTransform =
    gradientDirection === 'top-bottom' ? 'rotate(90)' : undefined

  return (
    <svg
      aria-labelledby={idAria}
      role='img'
      style={{ ...style, ...rtlStyle }}
      {...props}
    >
      {title ? <title id={idAria}>{title}</title> : null}
      {beforeMask && isValidElement(beforeMask) ? beforeMask : null}
      <rect
        role='presentation'
        x='0'
        y='0'
        width='100%'
        height='100%'
        clipPath={`url(${baseUrl}#${idClip})`}
        style={{ fill: `url(${baseUrl}#${idGradient})` }}
      />

      <defs>
        <clipPath id={idClip}>{children}</clipPath>

        <linearGradient id={idGradient} gradientTransform={gradientTransform}>
          <stop
            offset='0%'
            stopColor={backgroundColor}
            stopOpacity={backgroundOpacity}
          >
            {animate && (
              <animate
                attributeName='offset'
                values={`${-gradientRatio}; ${-gradientRatio}; 1`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount='indefinite'
                begin={animateBegin}
              />
            )}
          </stop>

          <stop
            offset='50%'
            stopColor={foregroundColor}
            stopOpacity={foregroundOpacity}
          >
            {animate && (
              <animate
                attributeName='offset'
                values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${
                  1 + gradientRatio / 2
                }`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount='indefinite'
                begin={animateBegin}
              />
            )}
          </stop>

          <stop
            offset='100%'
            stopColor={backgroundColor}
            stopOpacity={backgroundOpacity}
          >
            {animate && (
              <animate
                attributeName='offset'
                values={`0; 0; ${1 + gradientRatio}`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount='indefinite'
                begin={animateBegin}
              />
            )}
          </stop>
        </linearGradient>
      </defs>
      {afterMask && isValidElement(afterMask) ? afterMask : null}
    </svg>
  )
}

export default ContentLoader
