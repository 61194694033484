'use client'

import dynamic from 'next/dynamic'

import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal
} from 'component/ui/dialog'
import { usePathname } from 'lib/next-intl'
import { usePopupStore } from 'store/global/popup'
import { useIsMobile } from 'store/server-context/device'
import cn from 'util/cn'
import currentPage from 'util/currentPage'

const preventDefault = (event: any) => void event.preventDefault()

const AuthMain = dynamic(() => import('./authMain'), {
  loading: () => <div className='min-h-[310px]'></div>
})

const AuthModal = () => {
  const isOpen = usePopupStore(state => state.mask.auth)
  const showPopup = usePopupStore(state => state.showAuthPopup)
  const hidePopup = usePopupStore(state => state.hideAuthPopup)
  const pathname = usePathname()
  const { page } = currentPage(pathname)
  const isMobile = useIsMobile()

  const isCommunityPage = page === 'community'

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => (open ? showPopup() : hidePopup())}
      modal={!isMobile}
    >
      <DialogPortal>
        {!isMobile && <DialogOverlay />}
        <DialogContent
          className={cn(
            'w-[424px] playsee-mb:bottom-[calc(var(--cookie-hint-height)_+_96px)] playsee-mb:w-full',
            isCommunityPage &&
              'playsee-mb:bottom-[calc(var(--cookie-hint-height)_+_96px_+_64px)]'
          )}
          onPointerDownOutside={preventDefault}
        >
          <AuthMain onClose={hidePopup} />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default AuthModal
