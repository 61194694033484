'use client'

import { useTranslations } from 'next-intl'
import QRcode from 'qrcode'
import { useLayoutEffect, useRef } from 'react'

import Link from 'component/block/link'
import { DialogDescription, DialogTitle } from 'component/ui/dialog'
import { Image } from 'component/ui/image'
import { APP_DOWNLOAD_LINKS, CDN_URL, REDIRECT_APP_LINK } from 'constant'

const GetAppModal = () => {
  const t = useTranslations()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useLayoutEffect(() => {
    QRcode.toCanvas(
      canvasRef.current,
      REDIRECT_APP_LINK,
      { margin: 0, width: 210 },
      function (error) {
        if (error) console.error(error)
      }
    )
  }, [])

  return (
    <div className='flex w-full flex-col space-y-[24px] text-label-l1'>
      <DialogTitle className='text-center text-2xl font-semibold'>
        {t('overlays.download.title')}
      </DialogTitle>
      <DialogDescription className='text-center text-lg'>
        {t('overlays.download.qr_code')}
      </DialogDescription>
      <canvas ref={canvasRef} className='mx-auto block' />
      <div className='flex items-center justify-center space-x-[8px] pb-[16px]'>
        <Link
          href={APP_DOWNLOAD_LINKS.android}
          title='Download Playsee for Android'
          target='_blank'
        >
          <Image
            src={`${CDN_URL}/common/images/google_play_black.svg`}
            width={128}
            height={38}
            className='h-[38px] w-[128px]'
            alt='Google Play'
          />
        </Link>
        <Link
          href={APP_DOWNLOAD_LINKS.ios}
          title='Download Playsee for iOS'
          target='_blank'
        >
          <Image
            src={`${CDN_URL}/common/images/app_store_black.svg`}
            width={128}
            height={38}
            className='h-[38px] w-[128px]'
            alt='App Store'
          />
        </Link>
      </div>
    </div>
  )
}

export default GetAppModal
