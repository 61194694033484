'use client'

import { useTranslations } from 'next-intl'
import { toast } from 'react-hot-toast'

import IconPin from 'asset/icon/geo/pin_s.svg'
import IconCommunity from 'asset/icon/home/us_s.svg'
import {
  CategoryTitle,
  ListContainer,
  LoadingWrapper,
  SuggestedForYou,
  Title
} from 'component/block/layoutSuggested'
import Link from 'component/block/link'
import LoadingItems from 'component/block/loading/items'
import SuggestedListItem from 'component/block/suggestedListItem'
import { Image } from 'component/ui/image'
import { useRouter } from 'lib/next-intl'
import { useSpotLocationPickerStore } from 'store/global/location-picker'
import type {
  ICommunityLocationPickerV2,
  ISpotLocationPickerV2
} from 'type/feed'
import getFirstBatchLocation from 'util/getFirstBatchLocation'
import { normalizeAsset } from 'util/normalizeAsset'
import { useSearchSuggestion } from './use-search-suggestion'

const SearchSuggestion = () => {
  const t = useTranslations()
  const router = useRouter()

  const results = useSearchSuggestion()
  const setSpotPickedLocation = useSpotLocationPickerStore(
    state => state.setPickedLocation
  )
  const setCommunityPickedLocation = useSpotLocationPickerStore(
    state => state.setPickedLocation
  )

  const handleClickSpotItem = (location: ISpotLocationPickerV2) => {
    toast(
      t('feed.change_place_level_success', { location: location.display_name }),
      {
        duration: 2000
      }
    )
    setSpotPickedLocation(location)
    router.push('/spot')
  }

  const handleClickCommunityItem = (location: ICommunityLocationPickerV2) => {
    toast(
      t('feed.change_place_level_success', { location: location.display_name }),
      {
        duration: 2000
      }
    )
    setCommunityPickedLocation(location)
    router.push('/community')
  }

  const feedLocations = getFirstBatchLocation(results[0].data || [])
  const communityLocations = getFirstBatchLocation(results[1].data || [])

  return (
    <div>
      <SuggestedForYou>{t('common.suggested_for_you')}</SuggestedForYou>
      <ListContainer>
        {results[0].isLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          feedLocations.map((location, index) =>
            location.level_type !== 'spot' ? (
              <Title key={'search_feed' + index} title={location.display_name}>
                {location.display_name}
              </Title>
            ) : (
              <SuggestedListItem
                key={'search_feed' + index}
                icon={
                  <Image
                    src={normalizeAsset(location.asset).src}
                    alt={location.display_name}
                    fill={true}
                    fallback={<IconPin className='fill-current sq-[24px]' />}
                  />
                }
                title={location.display_name}
                onClick={() => handleClickSpotItem(location)}
              />
            )
          )
        )}
      </ListContainer>
      <CategoryTitle>{t('common.communities')}</CategoryTitle>
      <ListContainer>
        {results[1].isLoading ? (
          <LoadingWrapper>
            <LoadingItems />
          </LoadingWrapper>
        ) : (
          communityLocations.map((location, index) =>
            location.level_type !== 'community' ? (
              <Title key={'search' + index} title={location.display_name}>
                {location.display_name}
              </Title>
            ) : (
              <Link
                key={'search' + index}
                href={`/community/${location.content_ids?.[0]}`}
                title={location.display_name}
              >
                <SuggestedListItem
                  icon={
                    <Image
                      src={normalizeAsset(location.asset).src}
                      alt={location.display_name}
                      fill={true}
                      fallback={
                        <IconCommunity className='fill-current sq-[24px]' />
                      }
                    />
                  }
                  squareIcon={true}
                  title={location.display_name}
                  onClick={() => handleClickCommunityItem(location)}
                />
              </Link>
            )
          )
        )}
      </ListContainer>
    </div>
  )
}

export default SearchSuggestion
