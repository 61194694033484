import { Fragment } from 'react'

import { LoadingKey } from './key'
import ContentLoader from './react-content-loader'

const LoadingItems = ({ count = 6, viewBoxValue = '0 0 230 408' }) => {
  // count 為生成的 Loading items 數量
  return (
    <ContentLoader
      viewBox={viewBoxValue}
      uniqueKey={LoadingKey.items}
      foregroundColor='var(--background-1st)'
      backgroundColor='var(--background-2nd)'
      speed={1.5}
      interval={0.5}
      gradientRatio={1}
    >
      {Array.from({ length: count }).map((_, index) => (
        // 24px space between on each item
        <Fragment key={index}>
          {/* 48px circle */}
          <circle cx='24' cy={72 * index + 24} r='24' />

          {/* 100% - 48px - 8px length line / height 16 */}
          <rect
            x='56'
            y={72 * index + 6}
            rx='4'
            ry='4'
            width='158' // 230 - 56 - 16
            height='16'
          />
          {/* gap: 4px */}
          {/* 100% - 48px - 8px length line / height 16 */}
          <rect
            x='56'
            y={72 * index + (6 + 16 + 4)}
            rx='4'
            ry='4'
            width='158' // 230 - 56 - 16
            height='16'
          />
        </Fragment>
      ))}
    </ContentLoader>
  )
}

export default LoadingItems
