import { useTranslations } from 'next-intl'

import LanguageSelector from 'component/block/language-selector'
import { LEGAL_LINKS, PLAYSEE_LINKS } from 'constant'
import { Container, Item, List } from './footer.style'
import { usePopupStore } from 'store/global/popup'

const list = [
  { webKey: 'auth.form.account', href: undefined },
  { webKey: 'common.about', href: PLAYSEE_LINKS.about },
  { webKey: 'common.business', href: PLAYSEE_LINKS.business },
  { webKey: 'common.help_center', href: PLAYSEE_LINKS.help },
  { webKey: 'common.careers', href: PLAYSEE_LINKS.careers },
  {
    webKey: 'common.playsee_user_terms_of_service',
    href: LEGAL_LINKS.terms,
    auth: false
  },
  {
    webKey: 'common.playsee_privacy_notice',
    href: LEGAL_LINKS.privacy,
    auth: false
  }
] as const

type IDataInterface = {
  webKey: (typeof list)[number]['webKey']
  href: (typeof list)[number]['href']
}
const LinkItem: React.FC<IDataInterface> = props => {
  const t = useTranslations()
  const { webKey, href } = props

  return (
    <Item>
      <a href={href} target='_blank' rel='noopener noreferrer'>
        {t(webKey)}
      </a>
    </Item>
  )
}

const Footer = () => {
  const t = useTranslations()
  const showAccountPopup = usePopupStore(state => state.showAccountPopup)

  return (
    <Container>
      <List>
        {list.map((item, i) => (
          <li key={i}>
            {item.href ? (
              <LinkItem {...item} />
            ) : (
              <span
                className='cursor-pointer'
                onClick={() => {
                  if (item.webKey === 'auth.form.account') showAccountPopup()
                }}
              >
                {t(item.webKey)}
              </span>
            )}
          </li>
        ))}
        <li>
          <LanguageSelector className='w-auto min-w-0 border-0 bg-transparent text-label-l3' />
        </li>
        <li>
          <Item>©{new Date().getFullYear()} playsee</Item>
        </li>
      </List>
    </Container>
  )
}

export default Footer
