'use client'

import { useTranslations } from 'next-intl'
import React from 'react'

import Link from 'component/block/link'
import type { GuestInfo, UserInfo } from 'lib/auth'
import { usePathname, useRouter } from 'lib/next-intl'
import { useScrollIndex } from 'store/global/feed-scroll-index'
import {
  useCommunityLocationPickerStore,
  useSpotLocationPickerStore
} from 'store/global/location-picker'
import { usePopupStore } from 'store/global/popup'
import {
  useRefreshProfilePageStore,
  useRefreshSearchPageStore
} from 'store/global/refresh-page'
import { useIsMobile } from 'store/server-context/device'
import { useSessionStore } from 'store/server-context/session'
import { twc } from 'util/cn'
import currentPage from 'util/currentPage'
import scrollTop from 'util/scrollTop'

const StyledLink = twc(Link)`
  flex min-w-0 items-center space-x-[12px] p-[16px] text-2xl text-label-l1 hover:bg-background-2nd group
  min768:px-[20px] min768:py-[16px]
  playsee-mb:px-[8px] playsee-mb:py-[4px] playsee-mb:hover:bg-transparent
`
StyledLink.displayName = 'NavLink'

const StyledLabel = twc.span`hidden text-ellipsis min-w-0 shrink grow basis-0 min768:inline-block`
StyledLabel.displayName = 'NavLabel'

const svgClassName = (icon: string) => `[--icon-size:32px] shrink-0 ${icon}`

const profileLink = (session: UserInfo | GuestInfo) =>
  session.guest
    ? ''
    : session.uid
      ? `/${session.uid}`
      : `/profile/${session.user_id}`

interface INav {
  showProfile?: boolean
}
const Nav = (props: INav) => {
  const { showProfile } = props
  const pathname = usePathname()
  const session = useSessionStore(state => state.session)
  const t = useTranslations()

  const isOpen = usePopupStore(state => state.mask.auth)
  const showPopup = usePopupStore(state => state.showAuthPopup)
  const hidePopup = usePopupStore(state => state.hideAuthPopup)
  const isMobile = useIsMobile()
  const router = useRouter()
  const scrollIndex = useScrollIndex()

  const { page, id } = currentPage(pathname)
  const isProfile =
    !session.guest &&
    (page === 'profile' || page === 'profile_new') &&
    (id === session.uid || id === session.user_id)
  const isSpotFeed = page === 'spot-feed'
  const isCommunityFeed = page === 'community-feed'
  const isSearch = page === 'search'

  const refreshSpotLocationPicker = useSpotLocationPickerStore(
    state => state.refreshPicker
  )
  const refreshCommunityLocationPicker = useCommunityLocationPickerStore(
    state => state.refreshPicker
  )
  const refreshProfilePage = useRefreshProfilePageStore(
    state => state.refreshPage
  )
  const refreshSearchPage = useRefreshSearchPageStore(
    state => state.refreshPage
  )

  const handleClickOnMobile = () => {
    isMobile && isOpen && hidePopup()
  }

  const handleSpotClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isSpotFeed) {
      e.preventDefault()

      if (!isOpen) {
        if (scrollIndex > 0) {
          document.querySelector(`[data-scroll-index="${0}"]`)?.scrollIntoView()
        } else {
          router.refresh()
          refreshSpotLocationPicker()
        }
      }
    }

    handleClickOnMobile()
  }

  const handleCommunityClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isCommunityFeed) {
      e.preventDefault()

      if (!isOpen) {
        const scrollY = window.scrollY
        if (scrollY > 0) {
          scrollTop()
        } else {
          router.refresh()
          refreshCommunityLocationPicker()
        }
      }
    }

    handleClickOnMobile()
  }

  const handleClickProfile = (event: React.MouseEvent) => {
    if (session.guest) {
      event.preventDefault()
      showPopup()
      return
    }
    if (isProfile) {
      event.preventDefault()
      const scrollY = window.scrollY
      if (scrollY > 0) {
        scrollTop()
      } else {
        router.refresh()
        refreshProfilePage()
      }
    }
  }

  const handleSearchClick = (event: React.MouseEvent) => {
    if (isSearch) {
      event.preventDefault()

      if (!isOpen) {
        const scrollY = window.scrollY
        if (scrollY > 0) {
          scrollTop()
        } else {
          router.refresh()
          refreshSearchPage()
        }
      }
    }

    handleClickOnMobile()
  }

  const links = [
    {
      href: '/community',
      title: t('common.community'),
      icon: isCommunityFeed ? 'i-ps-group_f_big' : 'i-ps-group_s_big',
      onClick: handleCommunityClick,
      show: true
    },
    {
      href: '/spot',
      title: t('common.video'),
      icon: isSpotFeed ? 'i-ps-play_f_big' : 'i-ps-play_s_big',
      onClick: handleSpotClick,
      show: true
    },
    {
      href: '/search',
      title: t('common.search'),
      icon: isSearch ? 'i-ps-search_f_big' : 'i-ps-search_s_big',
      onClick: handleSearchClick,
      show: true
    },
    {
      href: profileLink(session),
      title: t('common.profile'),
      icon:
        !isSpotFeed && isProfile
          ? 'i-ps-user_rou_f_big'
          : 'i-ps-user_rou_s_big',
      onClick: handleClickProfile,
      show: showProfile || !session.guest
    }
  ]

  return (
    <>
      {links.map(
        link =>
          link.show && (
            <StyledLink
              key={link.title}
              href={link.href}
              title={link.title}
              onClick={link.onClick}
            >
              <div
                className={svgClassName(link.icon)}
                role='img'
                aria-label={link.title}
              />
              <StyledLabel>{link.title}</StyledLabel>
            </StyledLink>
          )
      )}
    </>
  )
}

export default Nav
