import { create } from 'zustand'

import type {
  ICommunityLocationPickerV2,
  ISpotLocationPickerV2
} from 'type/feed'

const createLocationPickerStore = <
  T extends ISpotLocationPickerV2 | ICommunityLocationPickerV2
>() => {
  interface ILocationPickerState {
    isRefresh: boolean
    refreshingId: string
    pickedLocation: T | null
    pickedLocationForSuggested: T | null
    currentGeo: {
      lat?: number
      lng?: number
      locationId?: string
    } | null
  }

  interface ILocationPickerAction {
    setPickedLocation: (location: T) => void
    refreshPicker: () => void
    updateCurrentXy: (geo: ILocationPickerState['currentGeo']) => void
    clearPickedLocation: () => void
  }

  return create<ILocationPickerState & ILocationPickerAction>()(set => ({
    isRefresh: false,
    refreshingId: '',
    currentGeo: null,
    pickedLocation: null,
    pickedLocationForSuggested: null,
    setPickedLocation: pickedLocation => {
      set({
        pickedLocation,
        pickedLocationForSuggested: pickedLocation,
        refreshingId: Date.now().toString()
      })
    },
    refreshPicker: () => {
      set({
        isRefresh: true,
        pickedLocation: null,
        currentGeo: null,
        refreshingId: Date.now().toString()
      })
    },
    updateCurrentXy: geo => {
      set({ currentGeo: geo })
    },
    clearPickedLocation: () => {
      set({
        pickedLocation: null,
        pickedLocationForSuggested: null,
        refreshingId: Date.now().toString()
      })
    }
  }))
}

export const useSpotLocationPickerStore =
  createLocationPickerStore<ISpotLocationPickerV2>()
export const useCommunityLocationPickerStore =
  createLocationPickerStore<ICommunityLocationPickerV2>()
