'use client'

import { useTranslations } from 'next-intl'
import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type MouseEvent
} from 'react'

import IconClose from 'asset/icon/close.svg'
import {
  GetAppDesktop,
  GetAppMobile,
  GetAppTablet
} from 'component/block/getApp'
import { Button } from 'component/ui/button'
import { Dialog, DialogPortal } from 'component/ui/dialog'
import { REDIRECT_APP_LINK } from 'constant'
import { GA_CLICK_ACTION, GA_SHOW_ACTION } from 'constant/ga'
import { usePickerOpenStore } from 'store/global/picker-open'
import { usePopupStore } from 'store/global/popup'
import { useDeviceStore } from 'store/server-context/device'

const DownloadBoard = () => {
  const t = useTranslations()
  const isDownloadOpen = usePopupStore(state => state.mask.download)
  const isAuthOpen = usePopupStore(state => state.mask.auth)
  const hidePopup = usePopupStore(state => state.hideDownloadPopup)
  const showCard = usePickerOpenStore(state => state.pickerOpen)
  const device = useDeviceStore(state => state.device)
  const ref = useRef<HTMLDivElement>(null)
  const [downloadBoardHeight, setDownloadBoardHeight] = useState<number>(0)

  const redirectToDownload = () => {
    window.open(REDIRECT_APP_LINK, '_blank')
  }

  const onCloseBoard = (event: MouseEvent) => {
    event.stopPropagation()
    hidePopup()
  }

  useLayoutEffect(() => {
    if (device !== 'tablet' && !ref.current) {
      return
    }

    const root = document.documentElement
    root.style.setProperty(
      '--download-board-height',
      `${downloadBoardHeight}px`
    )
  }, [device, downloadBoardHeight])

  useEffect(() => {
    if (!isDownloadOpen) {
      setDownloadBoardHeight(0)
      return
    }
    if (!ref.current || device !== 'tablet') {
      return
    }

    const resizeObserver = new ResizeObserver(entries => {
      const { height } = ref.current!.getBoundingClientRect()
      setDownloadBoardHeight(height)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [device, isDownloadOpen])

  // * Desktop doesn't show download board
  if (device === 'desktop') {
    return null
  }

  // when download popup is not open,
  // or when auth popup is open,
  // or when picker is open,
  // show download bar
  if (!isDownloadOpen || isAuthOpen || showCard) {
    return <GetAppMobile />
  }

  return (
    <Dialog open={isDownloadOpen} modal={false}>
      <DialogPortal>
        <div
          ref={ref}
          id='download-board'
          className='download-board'
          data-ga-show={GA_SHOW_ACTION.DownloadPopup.showAction}
          data-ga-click={GA_CLICK_ACTION.DownloadPopup.expBtnClickAction}
          onClick={redirectToDownload}
        >
          <div className='[grid-area:text] playsee-mb:text-center'>
            <div className='mb-[4px] text-2xl font-semibold text-label_still-l1'>
              {t('overlays.download.title')}
            </div>
            <div className='text-lg text-label_still-l2'>
              {t('overlays.download.description')}
            </div>
          </div>

          <Button
            className='w-[220px] [grid-area:button] playsee-mb:w-full'
            $variant='third'
            $radius='lg'
          >
            {t('overlays.download.button')}
          </Button>
          {device === 'mobile' && (
            <Button
              className='w-full [grid-area:close]'
              $variant='text-white'
              $size='sm'
              data-ga-click={GA_CLICK_ACTION.DownloadPopup.closeClickAction}
              onClick={onCloseBoard}
            >
              {t('mask.close')}
            </Button>
          )}
          {device === 'tablet' && (
            <Button
              className='absolute right-[12px] top-[12px] '
              $variant='text-white'
              $size='sm'
              $icon={true}
              data-ga-click={GA_CLICK_ACTION.DownloadPopup.closeClickAction}
              onClick={onCloseBoard}
            >
              <IconClose className='h-[20px] w-[20px] fill-current' />
            </Button>
          )}
        </div>
      </DialogPortal>
    </Dialog>
  )
}

export default DownloadBoard
