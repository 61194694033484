'use client'

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

const AlertDialog = AlertDialogPrimitive.Root
const AlertDialogTrigger = AlertDialogPrimitive.Trigger
const AlertDialogAction = AlertDialogPrimitive.Action
const AlertDialogCancel = AlertDialogPrimitive.Cancel
const AlertDialogPortal = AlertDialogPrimitive.Portal

type AlertDialogOverlayProps = TwcComponentWithAsChild<
  typeof AlertDialogPrimitive.Overlay
>
const AlertDialogOverlay = twc(
  AlertDialogPrimitive.Overlay
).attrs<AlertDialogOverlayProps>(props => ({
  asChild: props.$asChild
}))`fixed bottom-0 left-0 right-0 top-0 z-alert-1 bg-overlay-light playsee-mb:bottom-[--cookie-hint-height]
data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0`
AlertDialogOverlay.displayName = `twc(${AlertDialogPrimitive.Overlay.displayName}))`

type AlertDialogContentProps = TwcComponentWithAsChild<
  typeof AlertDialogPrimitive.Content
>
const AlertDialogContent = twc(
  AlertDialogPrimitive.Content
).attrs<AlertDialogContentProps>(props => ({
  asChild: props.$asChild
}))`fixed left-1/2 top-1/2 z-alert w-[330px] max-w-full -translate-x-1/2 -translate-y-1/2 transform rounded-[12px] bg-background-1st p-[24px]
data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]`
AlertDialogContent.displayName = `twc(${AlertDialogPrimitive.Content.displayName}))`

type AlertDialogTitleProps = TwcComponentWithAsChild<
  typeof AlertDialogPrimitive.Title
>
const AlertDialogTitle = twc(
  AlertDialogPrimitive.Title
).attrs<AlertDialogTitleProps>(props => ({
  asChild: props.$asChild
}))`text-center text-2xl font-semibold`
AlertDialogTitle.displayName = `twc(${AlertDialogPrimitive.Title.displayName}))`

type AlertDialogDescriptionProps = TwcComponentWithAsChild<
  typeof AlertDialogPrimitive.Description
>
const AlertDialogDescription = twc(
  AlertDialogPrimitive.Description
).attrs<AlertDialogDescriptionProps>(props => ({
  asChild: props.$asChild
}))`text-md text-label-l2`
AlertDialogDescription.displayName = `twc(${AlertDialogPrimitive.Description.displayName}))`

export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger
}
