import { useSyncExternalStore } from 'react'

let muted = true
type MutedListener = (muted: boolean) => void
let volumeListeners = new Set<MutedListener>()

export const mutedStore = {
  mute() {
    muted = true
    emitVolumeChange(muted)
  },
  unmute() {
    muted = false
    emitVolumeChange(muted)
  },
  toggle() {
    muted = !muted
    emitVolumeChange(muted)
  },
  subscribe(listener: MutedListener) {
    volumeListeners.add(listener)
    return () => {
      volumeListeners.delete(listener)
    }
  },
  getSnapshot() {
    return muted
  },
  getServerSnapshot() {
    return true
  }
}

function emitVolumeChange(muted: boolean) {
  for (let listener of volumeListeners) {
    listener(muted)
  }
}

export const useIsMuted = () =>
  useSyncExternalStore(
    mutedStore.subscribe,
    mutedStore.getSnapshot,
    mutedStore.getServerSnapshot
  )

let playState: 'playing' | 'paused' = 'playing'
type PlayStateListener = (playState: 'playing' | 'paused') => void
const isPlayingListeners = new Set<PlayStateListener>()

export const playStateStore = {
  play() {
    playState = 'playing'
    emitPlayStateChange(playState)
  },
  pause() {
    playState = 'paused'
    emitPlayStateChange(playState)
  },
  subscribe(listener: PlayStateListener) {
    isPlayingListeners.add(listener)
    return () => {
      isPlayingListeners.delete(listener)
    }
  },
  getSnapshot() {
    return playState
  }
}

function emitPlayStateChange(playState: 'playing' | 'paused') {
  for (const listener of isPlayingListeners) {
    listener(playState)
  }
}

let isUserPaused = false
type UserPausedListener = (isUserPaused: boolean) => void
const userPausedListeners = new Set<UserPausedListener>()

export const userPausedStore = {
  pause() {
    isUserPaused = true
    emitUserPausedChange(isUserPaused)
  },
  play() {
    isUserPaused = false
    emitUserPausedChange(isUserPaused)
  },
  toggle() {
    isUserPaused = !isUserPaused
    emitUserPausedChange(isUserPaused)
  },
  reset() {
    isUserPaused = false
    emitUserPausedChange(isUserPaused)
  },
  subscribe(listener: UserPausedListener) {
    userPausedListeners.add(listener)
    return () => {
      userPausedListeners.delete(listener)
    }
  },
  getSnapshot() {
    return isUserPaused
  },
  getServerSnapshot() {
    return false
  }
}

function emitUserPausedChange(isUserPaused: boolean) {
  for (let listener of userPausedListeners) {
    listener(isUserPaused)
  }
}

export const useIsUserPaused = () =>
  useSyncExternalStore(
    userPausedStore.subscribe,
    userPausedStore.getSnapshot,
    userPausedStore.getServerSnapshot
  )
